import { lazy, useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import { themeChange } from 'theme-change';
import './App.css';
import { AppRoutes } from './routes';

// Importing pages
const Layout = lazy(() => import('./containers/Layout'));
const Login = lazy(() => import('./pages/Login'));
const Forbidden = lazy(() => import('./pages/Forbidden'));

function App() {
  useEffect(() => {
    themeChange(false);
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path={AppRoutes.LOGIN} element={<Login />} />
          <Route path={AppRoutes.FORBIDDEN} element={<Forbidden />} />
          <Route path="/*" element={<Layout />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
